import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { navLogo } from "../../blocks/landingpage/src/assets";
import { IconButton, Typography } from "@material-ui/core";
import { profilePlaceholder } from "../../blocks/settings2/src/assets";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import { getStorageData,setStorageData, removeStorageData } from "../../framework/src/Utilities";
const baseURL = require("../../framework/src/config.js").baseURL
import { toast } from "react-toastify";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
interface myProps {
    navigation: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 40px',
        justifyContent: 'space-between',
        width: '90%',
        '@media(max-width:600px)':{
            padding:'20px',
            width:'100%'
        }
    },
    forMenues: {
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
        width: '580px',
        marginLeft: '30px',
        '@media(max-width:1024px)':{
            gap:'15px'
        },
        [theme.breakpoints.down(900)]: {
            display: 'none'
        }
    },
    forMenuItems: {
        fontSize: 18,
        fontWeight: 400,
        fontFamily: 'Roboto',
        textDecoration: 'none',
        color: '#828282',
        cursor: 'pointer',
    },
    activeMenuItem: {
        color: 'rgba(0, 105, 188, 1)',
        position: 'relative',
        '&::after': {
            content: '""',
            textDecoration: 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            borderBottom: '4px solid rgba(0, 105, 188, 1)'
        }
    },
    signUpButton: {
        color: '#ffffff',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        borderRadius: '8px',
        backgroundColor: '#0069BC',
        fontSize: '14px',
        height: '40px',
        border: 'none'
    },
    menuBar: {
        display: 'none',
        [theme.breakpoints.down(900)]: {
            display: 'flex',
            justifyContent: 'center',
            width: '25%'
        },
        '@media(max-width:600px)':{
            width:'auto'
        }
    },
    toastCSS: {
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '70%',
        },
    },
    name:{ 
        fontWeight: 500, 
        fontSize: '18px', 
        color: '#222222',
        textTransform: 'capitalize',
        maxWidth:'100px',
        width:'100%',
        overflow:'hidden',
        textOverflow:"ellipsis",
        whiteSpace:'nowrap',
        '@media(max-width:600px)':{
            fontSize:'14px',
            maxWidth:'58px'
        }
       },
      paper: {
        boxShadow: '0px 2px 4px 0px #00000026',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
      },
      burgerBtn: {
        color: '#222222'
      },
      logo: {
        cursor: 'pointer',
        width: '110px',
        marginLeft:'20px',
        '@media(max-width:600px)': {
          width: '80px',
          marginLeft:'0px',
        }
      }
}));

export default function TopNavBar(props: myProps) {

    const classes = useStyles();
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [anchorInvestMenu, setAnchorInvestMenu] = React.useState(null);
    const [profile, setProfile] = React.useState({ attributes: { first_name: '', photo: null } });
    const [loading, setLoading] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);

    const fetchSecretData = async () => {
        const URL = baseURL + '/account_block/profile'
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        fetch(URL, { headers: header })
            .then(response => response.json())
            .then(data => {
                if (data?.errors) {
                    removeStorageData("userInfo")
                    removeStorageData("token");
                    removeStorageData("kyc_status")
                    removeStorageData("userName")
                    removeStorageData("userId")
                    removeStorageData("wireTransfer")
                    removeStorageData("supportContact")
                    setShowLoader(false)
                    toast.error(data.errors[0])
                }

                if (data?.data) {
                    setProfile(data.data)
                    setStorageData("userName", data.data.attributes?.first_name);
                    setLoading(true)
                    setShowLoader(true)
                setStorageData("userInfo", JSON.stringify(data.data))}}).catch( ()=> {setProfile({ attributes: { first_name: '', photo: null } })
                setShowLoader(false)
            });
    }

    useEffect(() => {
        fetchSecretData()
    }, []);

    const open = Boolean(anchorMenu);
    const openInvest = Boolean(anchorInvestMenu);

    const handleMenuClick = (event: any) => {
        setAnchorMenu(event.currentTarget);
    };

    const handleInvestMenuClick = (event: any) => {
        setAnchorInvestMenu(event.currentTarget);
        setAnchorMenu(null)
    };

    const navigationPage = (path: string) => {
            customNavigationWeb(path)
    }

    const onImgClick = () => {
        if (window.location.pathname !== "/") {
            customNavigationWeb('HomeScreenWeb')
        }
    }

    const customNavigationWeb = (navigationPath: string) => {
        const goToNavigationBlockWeb: Message = new Message(getName(MessageEnum.NavigationMessage));
        goToNavigationBlockWeb.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
        goToNavigationBlockWeb.addData(getName(MessageEnum.NavigationPropsMessage), props);
        const uuidv4 = require("uuid/v4");
        const blockId = uuidv4();
        runEngine.sendMessage(blockId, goToNavigationBlockWeb);
    }
    const HandleNavigate = () =>{
        history.back()
    }
    return (
        <Paper elevation={0} square className={classes.paper} >
            <div className={classes.root}>
                <div style={{display:'flex',alignItems:'center'}}>
                {(window.location.pathname === "/CompanyPolicy/privacy_policy" || window.location.pathname === "/CompanyPolicy/terms_and_conditions")   &&  <div onClick={()=>HandleNavigate()}>
                    <ArrowBackOutlinedIcon />
                </div>
                }
                {(window.location.pathname=== "/CompanyPolicy/disclaimer" ||window.location.pathname=== "/InvestmentProcessWeb")  && localStorage.getItem('token') == null && <div>
                   
                </div>}
                {
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.menuBar}>
                                <IconButton
                                    id="basic-button"
                                    className={classes.burgerBtn}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleMenuClick}
                                >
                                    <MenuRoundedIcon />
                                </IconButton>
                            </div>
                            {localStorage.getItem('token') != null && (
                                showLoader && <img src={navLogo} alt="Poster" onClick={onImgClick} className={classes.logo} />
                            )}
                        </div>
                    }
                </div>
                <div className={classes.menuBar}>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorMenu}
                        open={open}
                        onClose={() => setAnchorMenu(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem id="home" onClick={(e) => {
                            customNavigationWeb('HomeScreenWeb')
                            setAnchorMenu(null)
                        }}>Home</MenuItem>
                        <MenuItem id="Invest" onClick={handleInvestMenuClick}>Invest</MenuItem>
                        <MenuItem id="About" onClick={(e) => {
                            customNavigationWeb('ContentManagement')
                            setAnchorMenu(null)
                        }}>About</MenuItem>
                        {localStorage.getItem('token') != null && <MenuItem id="Portfolio" onClick={(e) => {
                            customNavigationWeb('PortfolioManagementWeb')
                            setAnchorMenu(null)
                        }}>Portfolio</MenuItem>}
                        <MenuItem id="Secondaries" onClick={(e) => {
                             customNavigationWeb('SecondariesInvestmentWeb')
                            setAnchorMenu(null)
                        }}>Secondaries</MenuItem>
                         {localStorage.getItem('token') !== null && <MenuItem id="Account" onClick={(e) => {
                            setAnchorMenu(null)
                            customNavigationWeb('Settings')
                        }}>Account</MenuItem>}
                    </Menu>
                </div>          
                <div className={classes.forMenues} id="menuLinks">
                    <span
                        onClick={(e) => {
                            if (window.location.pathname !== "/") {
                                customNavigationWeb('HomeScreenWeb')
                            }
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/' ? classes.activeMenuItem : undefined}`}
                        id="home"
                    >
                        Home
                    </span>
                    <span
                        className={`${classes.forMenuItems} ${(window.location.pathname === '/StartupInvestmentWeb' || window.location.pathname === '/FundInvestmentWeb' || window.location.pathname === '/SecondariesInvestmentWeb' || window.location.pathname === '/InvestmentProcessWeb') ? classes.activeMenuItem : undefined}`}
                        id="Invest"
                        onClick={handleInvestMenuClick}
                    >
                        Invest
                    </span>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorInvestMenu}
                        open={openInvest}
                        onClose={() => setAnchorInvestMenu(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            id="Startup Investment"
                            onClick={() => {
                                navigationPage("StartupInvestmentWeb")
                            }}>
                            Startup Investment
                        </MenuItem>
                        <MenuItem
                            id="Fund Investment"
                            onClick={() => {
                                navigationPage("FundInvestmentWeb")
                            }}>
                            Fund Investment
                        </MenuItem>
                        <MenuItem
                            id="Secondaries"
                            onClick={() => {
                                customNavigationWeb('SecondariesInvestmentWeb')
                            }}>
                            Secondaries
                        </MenuItem>
                        <MenuItem
                            id="Investment Process"
                            onClick={() => {
                                customNavigationWeb('InvestmentProcessWeb')
                            }}>
                            Investment Process
                        </MenuItem>
                    </Menu>
                    
                    <span
                        className={`${classes.forMenuItems} ${(window.location.pathname === '/AboutUs'  ) ? classes.activeMenuItem : undefined}`}
                        id="About"
                        onClick={()=>{
                            customNavigationWeb('ContentManagement')
                        }}
                    >
                        About
                    </span>
                    

                    {localStorage.getItem('token') != null && <span
                        className={`${classes.forMenuItems} ${window.location.pathname === '/PortfolioManagementWeb' ? classes.activeMenuItem : undefined}`}
                        id="Portfolio"
                        onClick={() => {
                            customNavigationWeb('PortfolioManagementWeb')
                        }}
                    >
                        Portfolio
                    </span>}
                    <span
                        className={`${classes.forMenuItems} ${window.location.pathname === '/EducationCenterLandingWeb' || window.location.pathname === '/EducationCenterIntermediateWeb' || window.location.pathname === '/EducationCenterWeb' ? classes.activeMenuItem : undefined}`}
                        id="Secondaries"
                        onClick={() => {
                            customNavigationWeb('SecondariesInvestmentWeb')
                        }}
                    >
                        Secondaries
                    </span>
                    {localStorage.getItem('token') !== null && 
                    <span
                        className={`${classes.forMenuItems} ${window.location.pathname === '/Settings' ? classes.activeMenuItem : undefined}`}
                        id="Account"
                        onClick={() => navigationPage("Settings")}
                    >
                        Account
                    </span>
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                {loading && <Notifications id='' navigation={props.navigation} />}
                    {localStorage.getItem('token') == null && <button type="button" className={classes.signUpButton} onClick={(e: any) => {
                        customNavigationWeb('EmailAccountRegistrationWeb')
                    }}
                    >Get Started</button>}
                    {localStorage.getItem('token') != null &&
                        <div style={{ display: 'flex', gap: '15px',alignItems:'center' }}>
                           { showLoader ?  <img src={profile?.attributes?.photo === null ? profilePlaceholder : profile?.attributes?.photo} style={{ width: '48px', height: '48px', borderRadius: '50%' }} /> : <img src={profilePlaceholder} style={{ width: '48px', height: '48px', borderRadius: '50%',visibility:'hidden' }} />}
                            <div>
                                <Typography style={{ color: '#222222', fontSize: '14px' }}>Hello,</Typography>
                                <Typography className={classes.name}>{profile?.attributes?.first_name}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Paper>
    )
}
