import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import { getStorageData } from "../../framework/src/Utilities";

import { imglogo } from "../../blocks/forgot-password/src/assets";

interface myProps {
    navigation?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '90vw',
        padding: '20px 15px',
        gap: '25px'
    },
    footerTxt: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer'
    },
    footerLeftTxt: {
        color: '#FFFFFF',
        fontSize: '16px',
        maxWidth: '370px',
        marginTop: "20px",
        fontWeight: 400,
        '@media(max-width:768px)':{
            marginTop: "0px",
            maxWidth:'none'
        }
    },
    linksWrappper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '30%',
        gap: '20px',
        '@media(max-width:959px)':{
            width:'auto'
        }
     },
     logo:{
        cursor: 'pointer',
        height: 'auto',
        width: 'auto',
        maxWidth: '176px',
        maxHeight: '64px',
        '@media(max-width:600px)':{
            width:'120px'
        }
     }
}));

export default function Footer(props: myProps) {

    const classes = useStyles();
    const navigateToInvest = async () => {
        if (await getStorageData("token") === null) {
            props.navigation.navigate('EmailAccountRegistrationWeb')
        } else {
            props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'all'
            });
        }
    }

    const navigateToCompanyPolicy = async (param: string) => {
        props.navigation.navigate("TermsConditions", {
            type: param
        });
    }
    const navigateToAboutUs = async () => {
        props.navigation.navigate('ContentManagement');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }

    const navigateToHomePage = async () => {
        props.navigation.navigate('HomeScreenWeb');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }

    return (
        <Paper elevation={0} square style={{ background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)', display: 'flex', justifyContent: 'center' }}>
            <div className={classes.root}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap',gap:'20px', margin: '3%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '15px' }}>
                        <img src={imglogo} alt="logo" className={classes.logo} onClick={() => navigateToHomePage()} />
                        <Typography className={classes.footerLeftTxt} >Seamlessly entered the world of alternative investments with Future of Alts.</Typography>
                    </div>
                    <div className={classes.linksWrappper}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Typography className={classes.footerTxt} onClick={() => navigateToInvest()}>Invest</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToAboutUs()}>About</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('disclaimer')}>Disclaimer</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',gap:'10px' }}>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('terms_and_conditions')}>Terms & Conditions</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('privacy_policy')}>Privacy Policy</Typography>
                            <Typography style={{ color: 'transparent' }}> None </Typography>
                        </div>
                    </div>
                </div>
                <Divider style={{ background: '#FFFFFF' }} />
                <Typography style={{ color: '#FFFFFF', textAlign: 'center', fontSize: '16px', fontWeight: 600 }}>©{new Date().getFullYear()} Future For Alts. All rights reserved</Typography>
            </div>
        </Paper>
    )
}
