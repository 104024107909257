import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
    shape,
    imgArraow,
    imgBargraph,
    imgReport,
    imgShape,
    imgGroupSection,
    image_shape,
    frameShape,
    group_text02
} from "./assets";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import Footer from "../../../components/src/Footer.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';
const configJSON = require("./config");
// Customizable Area End

import LandingPageScreenController, {
    Props,
} from "./LandingPageScreenController.web";

const styles: StyleRules = {
    // Customizable Area Start
    backgroundImageStyle: {
        minHeight: '871px',
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundImage: `url(${imageHerosectionbackground})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media(max-width:959px)':{
            minHeight: 'auto',
            paddingBottom:'270px',
        }
    },
    mainHeaderTextStyle: {
        fontSize: '48px',
        fontWeight: 500,
        fontFamily: "Lato",
        textAlign: 'center',
        color: '#fff',
        '@media(max-width:959px)':{
            fontSize:"34px"
        },
        '@media(max-width:768px)':{
            fontSize:"28px"
        }
    },
    futureTextStyle: {
        color: '#00E644',
        fontSize: '48px',
        fontWeight: 900,
        fontStyle: 'italic',
        '@media(max-width:959px)':{
            fontSize:"34px"
        },
        '@media(max-width:600px)':{
            fontSize:"28px"
        }
    },
    titrleTextMainBoxStyle: {
        maxWidth: '1250px',
        width: '100%',
        minHeight: '298px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    seamlesslyStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        marginTop:"126px",
        maxWidth:'92%',
        '@media(max-width:959px)':{
            marginTop:"70px",
        }
    },
    descriptionMainTextStyle: {
        fontSize: '20px',
        fontFamily: "Roboto",
        textAlign: 'center',
        color: '#fff',
        '@media(max-width:600px)':{
            fontSize:"16px"
        }
    },
    groupcoinvesterStyle: {
        height: "243px",
        width: "1164px",
    },
    investorBoxStyle: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '223px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
        position: 'relative',
        marginTop:'-200px',
        '@media(max-width:600px)':{
            maxWidth:'90%'
        }
    },
    inverstorBoxWrapper:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    companyBoxStyle: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '376px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
        '@media(max-width:600px)':{
            maxWidth:'90%'
        }
    },
    companyButtonWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px',
        justifyContent: 'center',
        margin: '16px 0px 60px',
        '@media(max-width:600px)':{
            flexDirection:'column',
            alignItems:'center'
        }
    },
    companyBtnStyle: {
        borderRadius: "12px",
        height: "48px",
        fontSize: '18px',
        padding:'0 23px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
        '@media(max-width:600px)':{
            height:'auto'
        }
    },
    swiperInverstor: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'20px',
            marginBottom:'20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    swiperFirms: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'0px',
            marginBottom:'20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    containerImageStyle: {
        position: 'relative',
        width: '100%',
        maxWidth: '970px',
        margin: '0 auto',
        '@media (max-width: 1024px)': {
            maxWidth: '85%',
        },
        '@media (max-width: 600px)': {
            maxWidth: '90%',
        }
    },
    frameImgStyle: {
        width: '90%',
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity:0.3
    },
    groupSectionImgStyle: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: 1,
        margin: '148px 0px',
        '@media (max-width: 959px)': {
            margin: '70px 10px 70px',
        },
        '@media (max-width: 768px)': {
            margin: '70px 0',
        }
    },
    premierDigitalBox:{
        background: 'linear-gradient(92.83deg, #0086F0 0%, #0069BC 100%)',
        width: "100%",
        alignItems: 'center',
        color: "#fff",
        fontWeight: 600,
        textAlign: "center",
        fontFamily: "Lato",
        lineHeight: "68px",
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        minHeight: "320px",
        marginBottom: '148px',
        '@media (max-width: 959px)': {
            marginBottom: '70px',
        }
    },
    callBtn: {
        borderRadius: "12px",
        height: "60px",
        maxWidth: '200px',
        width: '100%',
        fontSize: '20px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        fontWeight: 700,
        border: '1px solid rgba(255, 255, 255, 1)',
        '@media(max-width:600px)':{
            height: "fit-content",
            fontWeight: 500,
            fontSize: '16px',
            width:'50%'
        }
    },
    investNowBtn: {
        borderRadius: "12px",
        height: "60px",
        maxWidth: '200px',
        width: '100%',
        fontSize: '20px',
        fontFamily: "Roboto",
        color: 'rgba(0, 105, 188, 1)',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
        '@media(max-width:600px)':{
            height: "fit-content",
            fontWeight: 500,
            fontSize: '16px',
            width:'50%'
        }
    },
    section: {
        scrollSnapAlign: 'start',
        animation: 'fadeIn 0.5s ease-out forwards',
    },
    premireDigital: {
        fontSize: "48px",
        fontWeight: 400,
        fontFamily: 'Lato',
        zIndex: 1,
        '@media(max-width:959px)': {
            fontSize: "34px",
            maxWidth:'85%'
        },
        '@media(max-width:600px)':{
            fontSize:'24px'
        }
    },
    cultivatingLongterm: {
        color: "#222222",
        fontSize: "42px",
        maxWidth: '1170px',
        width: '100%',
        fontWeight: 500,
        fontFamily: 'Lato',
        alignSelf: 'start',
        '@media(max-width:959px)': {
            fontSize: "34px",
        },
        '@media(max-width:600px)':{
            fontSize:'24px',
            textAlign:'center'
        }
    },
    ventureInvesting:{ 
        fontSize: "36px",
        fontWeight: 500,
        fontFamily: 'Lato',
        '@media(max-width:959px)': {
            fontSize: "24px",
        },
        '@media(max-width:600px)':{
            fontSize:'18px',
            textAlign:'center'
        }
    },
    arrow:{
        '@media(max-width:600px)':{
            width:"200px"
        }
    },
    OurCoInvestors:{ 
        fontSize: '28px',
        padding: '32px',
        fontWeight: 700,
        fontFamily: 'Lato',
        color: '#FFFFFF',
        '@media(max-width:600px)':{
            padding:'24px 16px 16px 32px'
        }
     },
     leveraging:{ 
         fontFamily: 'Roboto',
         fontSize: "20px",
         fontWeight: 400,
         color: '#828282',
         maxWidth: "510px",
         '@media(max-width:959px)': {
             fontSize: "16px",
             maxWidth: "none",
         },
     },
    weBringThis: {
        fontFamily: 'Roboto',
        color: '#828282',
        fontSize: "18px",
        maxWidth: "510px",
        '@media(max-width:959px)': {
            fontSize: "16px",
            maxWidth: "none",
        }
    },
    ventureWrapper: {
        gap: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        '@media(max-width:959px)': {
            gap: '30px',
        },
        '@media(max-width:600px)': {
            gap: '24px'
        }
    },
    ventureBlueBox: {
        background: "linear-gradient(60deg, #0086F0 , #0069BC)",
        color: "#FFFF",
        borderRadius: "16px",
        maxWidth: "451px",
        padding:'20px 10px',
        alignContent: "center",
        '@media(max-width:959px)': {
            maxWidth: "none",
            textAlign:'center',
            marginTop:'20px'
        }
    },
    broadenYourInvestment: {
        fontSize: "48px",
        color: '#FFFFFF', width: '100%',
        fontWeight: 600,
        fontFamily: 'Lato',
        alignSelf: 'start',
        '@media(max-width:959px)': {
            fontSize: "34px",
        },
        '@media(max-width:600px)':{
            fontSize:'24px',
            textAlign:'center'
        }
    },
    blueBoxText:{ 
        fontSize: "28px",
        lineHeight:"48px", 
        fontFamily: 'Lato', 
        color: '#FFFFFF', 
        fontWeight: 500,
        '@media(max-width:959px)':{
            fontSize:'22px',
            lineHeight:"34px", 
        },
        '@media(max-width:600px)':{
            fontSize:'18px',
            lineHeight:"28px", 
            textAlign:'center'
        }
    },
    wealthBoxStyle: {
        margin: '148px 0px',
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
        '@media(max-width:1280px)':{
            margin:'100px 50px'
        },
        '@media(max-width:959px)':{
            margin:'70px 50px'
        },
        '@media(max-width:600px)':{
            margin:'70px 20px',
            justifyContent:'center'
        }
    },
    cultivativeWrapper: {
        margin: '148px 0px',
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
        '@media(max-width:1280px)': {
            margin: '100px 50px'
        },
        '@media(max-width:959px)': {
            margin: '70px 50px'
        },
        '@media(max-width:600px)': {
            margin: '70px 20px',
            justifyContent: 'center'
        }
    },
    diversifyBeyond: {
        fontSize: "40px",
        fontFamily: 'Lato',
        fontWeight: 800,
        color: '#FFFFFF',
        '@media(max-width:959px)':{
            fontSize:'30'
        },
        '@media(max-width:600px)':{
            fontSize:'20'
        }
    },
    vantureMainWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media(max-width:959px)': {
            flexDirection: 'column',
            alignItems:'center'
        },
    },
    blueBoxWrapper: {
        maxWidth: "363px",
        margin: "auto",
        '@media(max-width:959px)': {
            maxWidth: "none",
        },
    },
    blueBoxBelowText: {
        fontSize: "16px",
        color: '#828282',
        fontFamily: 'Roboto',
        fontWeight: 500,
        maxWidth:"451px",
        '@media(max-width:959px)': {
            maxWidth: "none",
        },
    },
    barGraph:{
        width:'540px', 
        height:'459px',
        '@media(max-width:1150px)': {
            width:'450px',
            height:"auto",
        },
        '@media(max-width:959px)': {
            height:"auto",
            display:'block',
            margin:'0 auto'
        },
        '@media(max-width:600px)': {
            width:'100%'
        },
    },
    graphWrapper:{
        display:"flex",
        gap:'50px',
        alignItems:'flex-start',
        '@media(max-width:959px)': {
            flexDirection:'column',
            width:'100%',
            gap:"0"
        },
    },
    ventureInvestment: {
        fontSize: "48px",
        color: '#222222',
        maxWidth: '1006px',
        width: '100%',
        fontWeight: 600,
        fontFamily: 'Lato',
        alignSelf: 'start',
        '@media(max-width:959px)': {
            fontSize: "34px",
        },
        '@media(max-width:600px)': {
            fontSize: '24px',
            textAlign: 'center'
        }
    },
    ventureInvestmentSubtitle: {
        fontSize: "24px",
        fontFamily: 'Lato',
        color: '#222222',
        fontWeight: 600,
        '@media(max-width:959px)': {
            fontSize: "20px",
        },
        '@media(max-width:600px)': {
            fontSize: '16px',
            textAlign:'center'
        }
    },
    ventureInvestmentDescription: {
        fontSize: "18px",
        color: '#828282',
        fontWeight: 400,
        '@media(max-width:959px)': {
            fontSize: "20px",
        },
        '@media(max-width:600px)': {
            fontSize: '16px',
        }
    },
    reportWrapper:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '24px',
        marginTop:"60px",
        '@media(max-width:959px)': {
            marginTop:"10px",
        },
        '@media(max-width:600px)': {
            marginTop:"0px",
        }
     },
     premireShape1:{
        left: "0px",
        height: "198px",
        position: "absolute",
        top: "0px",
        width: "228px",
        '@media(max-width:600px)': {
            width:"100px",
            height:'auto'
        }
    },
    premireShape2:{
        right: "16px",
        position: "absolute",
        transform: " scaleY(1)",
        height: "198px",
        width: "228px",
        bottom: "0px",
        '@media(max-width:600px)': {
            width:'100px',
            height:'auto'
        }
    },
    source:{ 
        fontFamily: 'Roboto',
         color: '#828282', 
         fontSize: "18px", 
         fontWeight: 600,
         '@media(max-width:600px)': {
            fontSize:'16px'
         }
    },
    blueNowText: {
        fontFamily: 'Roboto',
        fontSize: "18px",
        color: '#F2F2F2',
        fontWeight: 700,
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    },
    topTier: {
        fontSize: "18px",
        fontFamily: 'Roboto',
        color: '#F2F2F2',
        fontWeight: 400,
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    },
    landingBtnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap: '30px',
        marginTop: '62px',
        '@media(max-width:600px)': {
            marginTop: '20px'
        }
    }
    // Customizable Area End
};

export class HomeScreenWeb extends LandingPageScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const breakPoints = {
            '@0.0': {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '@0.60': {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '@0.70': {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            '@0.90': {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            '@1.00': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            '@1.50': {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={{'overflowX': 'hidden'}}>
                <section className={classes.section}>
                <Box className={classes.backgroundImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titrleTextMainBoxStyle} style={{ position: 'relative' }}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '-50px',
                            top: '-15px',
                        }} />
                        <Box className={classes.seamlesslyStyle}>
                            <Typography className={classes.mainHeaderTextStyle}> Seamlessly enter the world of <span className={classes.futureTextStyle} style={{fontWeight:600}}> Alternative Investments </span> with <span className={classes.futureTextStyle} style={{fontWeight:600}}>Future for Alts.</span></Typography>
                            <Typography className={classes.descriptionMainTextStyle}>The world's only platform offering both, a website and application, for effortless access.</Typography>
                            {this.state.token !== null && <Box className={classes.landingBtnWrapper}>
                                <Button className={classes.callBtn} onClick={this.handleScheduleCall}>{configJSON.ScheduleCall}</Button>
                                <Button className={classes.investNowBtn} onClick={this.handleInvestNow}>{configJSON.InvestNow}</Button>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
                </section>
                <Box className={classes.inverstorBoxWrapper}>
                    <Box className={classes.investorBoxStyle}>
                        <Typography className={classes.OurCoInvestors}>Our Co-Investors</Typography>
                        <img src={imgArraow} className={classes.arrow} />
                            <Swiper
                                slidesPerView={5}
                                navigation
                                autoplay={{ delay: 700 }} 
                                className={classes.swiperInverstor}
                                speed={1500}
                                loop
                                breakpoints={breakPoints}
                            
                            >
                                {this.state.investorDataRecords.map((data: any, index: number) => (
                                    <SwiperSlide style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'auto',
                                    }}>
                                        <img
                                            src={data.attributes?.image}
                                            key={index}
                                            alt={data.attributes.company_name}
                                            style={{
                                                width: '30%',
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                    </Box>
                    </Box>
                <section className={classes.section}>
                <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12}>
                    <div className={classes.containerImageStyle}>
                        <img src={frameShape} className={classes.frameImgStyle} />
                        <img src={group_text02} className={classes.groupSectionImgStyle} />
                    </div>
                  </Grid>
                </Grid>
                </section>
                <section className={classes.section}>
                    <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>

                    <Grid
                        item
                        xs={12}
                        className={classes.premierDigitalBox}
                    >
                        <img
                            alt="group_shape"
                            src={shape}
                            className={classes.premireShape1}
                        />
                        <Typography className={classes.premireDigital}>
                            {configJSON.PremierDigitalText} <br />
                            <span
                                style={{
                                    fontStyle: "italic",
                                    color: "#00E644",
                                    fontWeight: 600,
                                }}
                            >
                                Alternative Investing.
                            </span>
                        </Typography>
                        <img
                            src={image_shape}
                            alt="group_shape"
                            className={classes.premireShape2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box className={classes.companyBoxStyle}>
                                <Typography className={classes.mainHeaderTextStyle} style={{ padding: '32px' }}>
                                    One <span className={classes.futureTextStyle} style={{ color: '#0069BC' }}>Platform, </span>
                                    Multiple <span className={classes.futureTextStyle} style={{ color: '#0069BC' }}>Opportunities</span>
                                </Typography>
                                <Box className={classes.companyButtonWrapper}>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'startup' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'startup' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("startup")}>Startup</Button>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'funds' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'funds' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("funds")}>Venture Funds</Button>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'secondary' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'secondary' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("secondary")}>Secondaries</Button>
                                </Box>
                                <Box  >
                                    <Swiper
                                        navigation
                                        autoplay={{ delay: 700 }}
                                        speed={1500}
                                        className={classes.swiperFirms}
                                        loop
                                        breakpoints={breakPoints}
                                    
                                     >
                                        {this.state.companyData.map((data: any, index: number) => (
                                            <SwiperSlide style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 'auto',
                                            }}>
                                                <img
                                                    src={data.attributes?.image}
                                                    key={index}
                                                    alt={data.attributes.company_name}
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                </section>

                <section className={classes.section}>                
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.cultivativeWrapper} >
                        <Typography className={classes.cultivatingLongterm}>
                            Cultivate Long-Term Wealth Generation with{"  "}
                            <span
                                style={{
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                    color: "#0069BC",
                                }}
                            >
                                Venture Investing
                            </span>
                        </Typography>
                        <Box className={classes.vantureMainWrapper}>
                            <Box className={classes.ventureWrapper} >
                                <Typography className={classes.ventureInvesting}>
                                    Venture investing stands distinguished for its steadfast
                                    commitment to long-term.
                                </Typography>
                                <Typography className={classes.leveraging}>
                                    Leveraging our industry expertise and investment focus, <b style={{fontWeight:700}}>we excel
                                        at identifying and accessing exclusive top-tier investment
                                        opportunities</b> that are typically inaccessible.
                                </Typography>
                                <Typography className={classes.weBringThis}>
                                    We bring these opportunities within your reach, <b>enabling
                                        long-term wealth generation.</b>
                                </Typography>
                            </Box>
                            <Box style={{ gap: '24px', display: 'flex', flexDirection: 'column',width:'100%'}}>
                                <Box className={classes.ventureBlueBox}>
                                    <Box className={classes.blueBoxWrapper}>
                                    <Typography className={classes.blueBoxText}>
                                        Venture investments can deliver an{" "}
                                        <span style={{ color: "#00EC44", fontStyle: "italic", fontWeight: 600 }}>
                                            average return of 55 times
                                        </span>{" "}
                                        the initial investment over a holding period of approximately
                                        eight years.
                                    </Typography>
                                    </Box>
                                    
                                </Box>
                                <Typography className={classes.blueBoxBelowText}>
                                    Past performance is not indicative of future results. The
                                    conclusive data is driven by analysis of multiple data sets.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>

                <section className={classes.section} style={{background: "linear-gradient(60deg, #0086F0 , #0069BC)"}}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.wealthBoxStyle}>
                        <Typography className={classes.broadenYourInvestment}>
                            Broaden your{" "}
                            <span style={{ color: "#00E644", fontStyle: "italic", fontWeight: 800, }} >
                                Investment Portfolio
                            </span>{" "}
                            by Diversifying
                        </Typography>
                        <Box className={classes.graphWrapper}>
                            <Box >
                                <img src={imgBargraph} alt="bargraph" className={classes.barGraph} />
                                <Box style={{ padding: '24px 0px'}}>
                                    <Typography style={{ fontFamily: 'Roboto', color: '#ffffff', fontSize: "16px", fontWeight: 500 }}>Hypothetical model portfolios shown above are for illustrative purposes only. Past performance is not indicative of future results</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.ventureWrapper}>
                                <Typography className={classes.diversifyBeyond}>
                                    Diversify beyond startups, encompassing funds and secondary
                                    investments.
                                </Typography>
                                <Typography className={classes.topTier}>
                                    Accessing top-tier alternative investments enables
                                    diversification and potentially superior risk-adjusted
                                    returns, a strategy employed by large institutional investors
                                    to outperform traditional stock and debt portfolios.
                                </Typography>
                                <Typography className={classes.blueNowText}>
                                    Now, individuals, small institutions, and family offices can
                                    also benefit from this strategy through our platform.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>

                
                <section className={classes.section}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.cultivativeWrapper}>
                        <Typography className={classes.ventureInvestment}>
                            <span style={{ color: "#0069BC", fontStyle: "italic", fontWeight: 700 }} >
                                Venture Investments:{" "}
                            </span>
                            Surpassing PE Funds with Superior Returns
                        </Typography>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <Typography className={classes.ventureInvestmentSubtitle}>
                                There has been no better time to start investing in alternative
                                asset classes.
                            </Typography>
                            <Typography className={classes.ventureInvestmentDescription}>
                                Over the past two decades, private markets have consistently
                                outperformed their corresponding public market indexes.<br /> Private
                                equity (PE) has been a prominent outperformer, but venture
                                investing has outperformed private equity (PE) with even higher
                                returns in the last decade.
                            </Typography>
                        </Box>
                        <Box className={classes.reportWrapper}>
                            <img src={imgReport} style={{width:'100%'}} alt="report" />
                            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                <Typography className={classes.source}>
                                    Past performance is not indicative of future results.
                                </Typography>
                                <Typography className={classes.source}>
                                    Source : Mckinsey & Company
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>
                <section className={classes.section}>
                <Footer navigation={this.props.navigation}/>
                </section>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(HomeScreenWeb);
// Customizable Area End
