import React from "react";

// Customizable Area Start
import {

    Button,
    Paper,
    Typography,
    Container,
    StepLabel,
    Step,
    Stepper,
    Grid
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import Footer from "../../../components/src/Footer.web";
import TopNavBar from "../../../components/src/TopNavbar.web";
import { investment1, investment2, investment3, investment4 } from "./assets";
// Customizable Area End

import InvestmentProcessController, {
    Props,
} from "./InvestmentProcessController.web";

const styles: StyleRules = {
    // Customizable Area Start
    investmentProcessContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '58px',
        padding: '74px 0px',
        "& .investmentProcessText": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '40px',
            color: 'rgba(0, 105, 188, 1)',
            paddingLeft: "45px"
        },
        '@media (max-width: 959px)': {
            gap: '170px',
        },
    },
    investmentProcessStyle: {
        display: 'flex',
        padding:'0px 0px 52px',
        justifyContent:'center',
        flexWrap: 'nowrap',
        '@media (max-width: 1200px)': {
            padding:'0px 20px 52px 20px'
        },
        '@media (max-width: 959px)': {
            "& img": {
                display: "none"
            }
        }
    },
    stepper: {
        padding: '0px 16px 24px',
        '& .MuiStepIcon-root': {
            color: 'white',
            width: '70px',
            height: '100px',
            zIndex: 1
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            fontWeight: 600
        },
        '& .MuiStepLabel-label': {
            fontSize: '18px'
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: 'rgba(0, 105, 188, 1)',
            width:"70px",

            '& .MuiStepIcon-text': {
                fill: 'white',
                fontWeight: 700,
                fontFamily: 'Lato'
            },
        },
        '& .MuiStepIcon-text': {
            fill: 'rgba(0, 105, 188, 1)',
            fontWeight: 700,
            fontFamily: 'Lato'
        },
        '& .MuiStepConnector-active': {
        },
        '& .MuiStepLabel-iconContainer' : {
            paddingTop: "30px",
            paddingBottom: "40px",
            paddingLeft:"15px",
            '@media(max-width:600px)':{
                paddingLeft:"0px",
            }
        },
        '& .MuiStepConnector-lineVertical': {
            minHeight: '132px',
            borderLeft: '2px solid black',
            margin: '-50px 40px',
            '@media(max-width:600px)':{
                margin:'-50px 20px'
            }
        }
    },
    activeClass: {
        width: 'auto',
        maxWidth: '443px',
        borderRadius: '16px',
        backgroundColor: 'rgba(0, 105, 188, 1)',
        color: 'rgba(255, 255, 255, 1)',
        display: 'flex',
        gap: '15px',
        flexDirection: 'column',
        padding: '12px 30px',
        height:"140px",
        '@media (max-width: 600px)': {
            gap: '0px',
            padding: '12px 15px',
        }
    },
    activeHeading: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '24px',
        '@media (max-width: 600px)': {
            fontSize: '18px',
        }
    },
    activeDescription: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '16px',

    },
    browseBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        height: "60px",
        width: "271px",
        color: "#FFFFFF",
        borderRadius: "12px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        margin: '24px 0px 10px 100px',
        '@media(max-width:600px)':{
            marginLeft:"0"
        }
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'end',
        '@media(max-width:600px)': {
            justifyContent: 'center',
        }
    }
    // Customizable Area End
};

export class InvestmentProcessWeb extends InvestmentProcessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    arrayOfImages = [investment1, investment2, investment3, investment4]
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { activeStep, investmentDetails } = this.state;
        const { classes, } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <TopNavBar navigation={this.props.navigation} />
                <Container className={classes.investmentProcessContainerStyle}>
                    <Grid>
                        <Typography className="investmentProcessText">Investment Process</Typography>
                    </Grid>
                    <Grid container className={classes.investmentProcessStyle}>
                        <Grid style={{ display: 'flex', gap: '99px', flexDirection: 'column', marginTop: "40px" }}>
                            <Grid>
                                <img src={this.arrayOfImages[activeStep]} style={{ maxWidth: '560px', width: '100%' }} />
                            </Grid>
                        </Grid>
                        <Grid >
                            <Stepper className={classes.stepper}
                                activeStep={activeStep}
                                orientation="vertical">
                                {investmentDetails.data?.map((label: any, index: number) => (    
                                    <Step key={label.id} completed={false} className="scroll-content">
                                        {activeStep === index ? <StepLabel >
                                            <Grid className={classes.activeClass}>
                                                <Typography className={classes.activeHeading}>{label?.attributes?.title}:</Typography>
                                                <Typography className={classes.activeDescription}>{label?.attributes?.description}</Typography>
                                            </Grid>
                                        </StepLabel> :
                                            <StepLabel>
                                                <Typography className={classes.activeDescription} style={{ maxWidth: '433px', width: '100%' }}><span style={{ fontWeight: 800, color: 'rgba(34, 34, 34, 1)' }}>{label?.attributes?.title}:</span>{label?.attributes?.description}</Typography>
                                            </StepLabel>}
                                    </Step>
                                ))}
                            </Stepper>
                            <Grid className={classes.btnWrapper}>
                                <Button className={classes.browseBtn} onClick={this.navigateToInvestList} data-test-id="browseBtn">Browse Opportunities</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(InvestmentProcessWeb);
// Customizable Area End
