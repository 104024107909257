import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { frameShape, imgShape, navLogo } from "../../landingpage/src/assets";
import { imageHerosectionbackground, imgEllips } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { imgFive, imgFour, imgOne, imgThree, imgTwo, imgZero,GroupArrowBig,imgBigTwo,imgBigZero,imgBigOne } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImageStyle: {
        backgroundImage: `url(${imageHerosectionbackground})`,
        minHeight: '871px',
        backgroundRepeat: "round",
        backgroundSize: "cover",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '123px',
        '@media (max-width: 1280px)': {
            minHeight: '670px',
            gap: '70px'
        },
        '@media (max-width: 959px)': {
            minHeight: '570px',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            minHeight: '449px',
            gap: '26px'
        }
    },
    titleTextMainBox: {
        maxWidth: '1170px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        '@media(max-width:1200px)':{
            padding:'0 20px'
        },
    },
    headerMainText: {
        fontSize: '64px',
        fontWeight: 400,
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        maxWidth: '925px',
        width: '100%',
        '@media (max-width: 1280px)': {
            fontSize: '52px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            marginBottom: "0px"
        }
    },
    directlyInvestStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        "& .directlyInvestHeading": {
            fontSize: '48px',
            fontFamily: 'Lato',
            fontWeight: 600,
            textAlign: 'center'
        },
        "& .startupInvestBox": {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            "& .startupInvestBoxStyle": {
                display: 'flex',
                gap: '32px'
            }
        },
        "& .directlyInvestText": {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '15px'
        },
        "& .directStartupHeading": {
            fontSize: '40px',
            fontFamily: 'Lato',
            fontWeight: 800,
            textAlign: 'center'
        },
        "& .opportunityDataStyle": {
            display: 'flex',
            gap: '30px',
            flexWrap: 'nowrap'
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            "& .directlyInvestHeading": {
                fontSize: '40px',
            },
            "& .startupInvestBox": {
                "& img": {
                    display: 'none'
                },
                "& .startupInvestBoxStyle": {
                    flexDirection: 'column'
                }
            },
            "& .directlyInvestText": {
                order: 1
            },
            "& .directStartupHeading": {
                fontSize: '32px',
            },
            "& .opportunityDataStyle": {
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
        },
        '@media (max-width: 600px)': {
            "& .directlyInvestHeading": {
                fontSize: '28px',
            },
            "& .directStartupHeading": {
                fontSize: '24px',
            },
        }
    },
    futureText: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitiesBtn: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '319px',
        width: '100%',
        height: '60px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        }
    },
    startupBox: {
        maxWidth: '370px',
        height: 'max-content',
        width: '100%',
        padding: '32px',
        background: 'rgba(229, 240, 248, 1)',
        borderRadius: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        position: 'relative',
        '@media (max-width: 959px)': {
            top: "0 !important",
            width: "auto",
            maxWidth:'none'
        }
    },
    ellipsBox: {
        backgroundImage: `url(${imgEllips})`,
        backgroundSize: "806px",
        backgroundPositionX: 'center',
        backgroundRepeat: "no-repeat",
        maxWidth: '1169px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        "& .ellipsBox1": {
            alignSelf: 'center',
            position: 'relative',
            zIndex: 1,
            top: '-75px'
        },
        "& .ellipsBox2": {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 1,
            top: '-37px',
            "& .ellipsBox21": {
                marginLeft: '100px',
                "& .ellipsBox21Image": {
                    margin: '0px -24px 0px 24px'
                }
            },
            "& .ellipsBox22": {
                marginRight: '100px'
            }
        },
        "& .ellipsBox3": {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 1,
            marginTop: '161px',
            "& .ellipsBox31": {
                marginLeft: '66px',
                "& .ellipsBox31Image": {
                    margin: '0px -24px 0px 24px'
                }
            },
            "& .ellipsBox32": {
                display: 'none'
            },
            "& .ellipsBox33": {
                "& .ellipsBox33Image1": {
                    position: 'relative',
                    left: '232px'
                },
                "& .ellipsBox33Image2": {
                    position: 'absolute',
                    zIndex: 1,
                    right: '34px'
                }
            }
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            alignItems: 'center',
            backgroundImage: 'none',
            "& .ellipsBox1": {
                order: 1,
                top: '0px'
            },
            "& .ellipsBox2": {
                order: 2,
                top: '0px',
                "& .ellipsBox21": {
                    marginLeft: '0px !important',
                    "& .ellipsBox21Image": {
                        margin: '0px -24px 0px 75px !important'
                    }
                },
                "& .ellipsBox22": {
                    display: 'none'
                }
            },
            "& .ellipsBox3": {
                order: 3,
                marginTop: '0',
                flexDirection: 'column',
                alignItems: 'center',
                "& .ellipsBox31": {
                    marginLeft: '0px !important',
                    "& .ellipsBox31Image": {
                        margin: '0px -24px 0px 60px !important'
                    }
                },
                "& .ellipsBox32": {
                    display: 'block !important'
                },
                "& .ellipsBox33": {
                    "& .ellipsBox33Image1": {
                        left: '0px !important',
                        margin: '0px -24px 0px 132px !important'
                    },
                    "& .ellipsBox33Image2": {
                        right: 'auto !important'
                    }
                }
            },
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            marginBottom: "0px"
        }
    },
    futureBox: {
        border: '2px solid rgba(0, 105, 188, 1)',
        borderRadius: '80px',
        padding: '24px 40px',
        fontSize: '24px',
        fontFamily: 'Lato',
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        textAlign: 'center',
        marginTop: '-32px',
        background: 'white',
        position: 'relative'
    },
    imageContainer: {
        position: 'absolute',
        width: '100%',
        maxWidth: '400px',
        left: '34%',
        top: '182px',
        '@media (max-width: 959px)': {
            position: 'relative',
            order: 0,
            left: 0,
            top: 0,
            width: 'auto',
            maxWidth: 'none'
        },
        '@media (max-width: 600px)': {
        }
    },
    frameImg: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity:"0.2",
        '@media (max-width: 959px)': {
            display: 'none'
        }
    },
    logoImg: {
        width: '100%',
        maxWidth: '221px',
        left: '25%',
        position: 'relative',
        zIndex: 1,
        padding: '160px 10px 80px',
        '@media (max-width: 959px)': {
            left: 0,
            padding: "0px"
        }
    },
    textStyle: {
        fontWeight: 500,
        fontSize: '16px',
        fontFamily: 'Roboto'
    },
    Imgnumbers :{
        height:"154px",
        width:"75px"
    },
    FundBox :{
        fontSize: '32px',
        fontFamily: 'Lato',
        fontWeight: 600,
        position: 'relative',
        backgroundColor: '#F2F8FC',
        alignContent:"end",
        width: '100%',
        borderRadius: '16px',
        overflow: 'hidden',
        paddingTop:'82px',
        '@media (max-width: 959px)': {
            paddingTop:'35px',
        }
    },
    investBtn: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: '#ffffff',
        maxWidth: '319px',
        width: '100%',
        height: '60px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 1)',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        }
    },
    containerStyle: {
        display: "flex",
        justifyContent: "center",
        padding: '40px 0px',
        margin: '80px 0px',
        '@media (max-width: 959px)': {
            padding: "0px",
            margin: '70px 0px !important'
        },
    }
    // Customizable Area End
};

export class StartupInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { startupInvestData, opportunityData, feeStructureData } = this.state;

        return (
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titleTextMainBox}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '0px',
                            top: '-15px',
                        }} />
                        <Typography className={classes.headerMainText}> Experience the benefits of <br/> <span className={classes.futureText}>Direct Startup Investing </span></Typography>
                    </Box>
                    <Button className={classes.opportunitiesBtn} onClick={this.startupInvestmentDetails}>View Available Opportunities</Button>
                </Box>

                <Grid container className={classes.containerStyle}>
                    <Grid className={classes.ellipsBox}>
                        <Box className="ellipsBox1">
                            <img src={imgZero} style={{ margin: '0px -24px 0px 64px' }} />
                            <img src={imgOne} style={{ position: 'absolute', zIndex: 1 }} />
                            <Box className={classes.futureBox} style={{ maxWidth: '154px' }}>
                                High potential for returns
                            </Box>
                        </Box>
                        <Box className="ellipsBox2">
                            <Box className="ellipsBox21">
                                <img src={imgZero} className="ellipsBox21Image" />
                                <img src={imgTwo} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '179px' }}>
                                    Early access to innovative ideas
                                </Box>
                            </Box>
                            <Box className="ellipsBox22">
                                <img src={imgZero} style={{ position: 'relative', left: '96px' }} />
                                <img src={imgFour} style={{ position: 'absolute', zIndex: 1, right: '133px' }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '156px' }}>
                                    Portfolio Diversification
                                </Box>
                            </Box>
                        </Box>
                        <Box className="ellipsBox3">
                            <Box className="ellipsBox31">
                                <img src={imgZero} className="ellipsBox31Image" />
                                <img src={imgThree} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '146px' }}>
                                    Value beyond capital
                                </Box>
                            </Box>
                            <Box className="ellipsBox32">
                                <img src={imgZero} style={{ position: 'relative', margin: '0px -24px 0px 65px' }} />
                                <img src={imgFour} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '156px' }}>
                                    Portfolio Diversification
                                </Box>
                            </Box>
                            <Box className="ellipsBox33">
                                <img src={imgZero} className="ellipsBox33Image1" />
                                <img src={imgFive} className="ellipsBox33Image2" />
                                <Box className={classes.futureBox} style={{ maxWidth: '289px' }}>
                                    Active participation in the entrepreneurial ecosystem
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.imageContainer}>
                            <img src={frameShape} className={classes.frameImg} />
                            <img src={navLogo} className={classes.logoImg} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.directlyInvestStyle}>
                        <Typography className="directlyInvestHeading">
                            Why directly invest in <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Startups</span>?
                        </Typography>
                        <Grid item className="startupInvestBox">
                            <img src={GroupArrowBig} alt="" style={{ width: '100%', maxWidth: '800px' }} />
                            <Box className="startupInvestBoxStyle">
                                <Box className={classes.startupBox} style={{top:"10px"}}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.sub_title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBox} style={{ background: 'rgba(0, 105, 188, 1)', top: '-170px' }}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '18px', fontFamily: 'Lato', wordWrap: 'break-word', fontWeight: 400, color: '#FFFFFF' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBox} style={{ top: '-330px' }}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400, wordWrap: 'break-word' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.content}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {feeStructureData != undefined ?
                    <Grid container className={classes.containerStyle}>
                        <Grid container className={classes.directlyInvestStyle}>
                            <Typography className="directlyInvestHeading">
                                Simplified <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 700 }}>Fee Structure</span>
                            </Typography>

                            <Grid container style={{ width: '100%',margin:"96px 0px 36px" }}>
                                
                                <Grid item md={6} xs={12} style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingRight: '15px' }}>
                                    <div style={{ position: 'absolute', top: "-77px", left: "32px", display: 'flex' }}>
                                        <img
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigOne} className={classes.Imgnumbers} style={{ marginLeft: '-12px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox}>
                                    <Typography style={{ fontSize: '32px',padding:"32px" ,fontFamily: 'Lato', fontWeight: 600, backgroundColor: 'rgb(242, 248, 252)' }}>
                                        Invest without <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>upfront fees</span> - pay only when you invest.
                                    </Typography>
                                    </Box>

                                </Grid>
                                 
                                 <Grid item md={6} xs={12} className="directlyInvestText">
                                 <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: feeStructureData[0]?.attributes?.content }}></Typography>
                                
                                </Grid>
                            </Grid>
                            
                             <Grid container style={{ width: '100%' ,marginTop:"20px"}}>
                                
                                 <Grid item md={6} xs={12} className="directlyInvestText">
                                 <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: feeStructureData[1]?.attributes?.content }}></Typography>
                                
                                </Grid>
                              
                                <Grid item md={6} xs={12} style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingLeft: '15px' }}>
                                    <div style={{ position: 'absolute', top: "-77px", left: "45px", display: 'flex' }}>
                                        <img
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigTwo} className={classes.Imgnumbers} style={{ marginLeft: '-10px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox} style={{fontSize: '32px' ,fontFamily: 'Lato', fontWeight: 600,}}>
                                    <Typography style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 600, padding: '32px' }}>
                                        <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Transparent fees</span> for informed investments - know the fees before you invest.
                                    </Typography>
                                    </Box>

                                </Grid>
                                
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    : ''}

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.directlyInvestStyle}>
                        <Typography style={{ fontSize: '18px', fontFamily: 'Lato', color: 'rgba(0, 105, 188, 1)', fontWeight: 400, textAlign: 'center' }}>
                            From Our Past Successes to Your Future Opportunities
                        </Typography>
                        <Typography className="directStartupHeading">
                            Our Direct Startup Investments
                        </Typography>
                        <Grid className="opportunityDataStyle">
                            {opportunityData?.slice(0, 2).map((item: any) => (
                                <Grid style={{ display: 'flex', flexDirection: 'column', padding: '5px',border:'1px solid rgba(111, 111, 112, 1)', borderRadius:'12px',backgroundColor:'rgb(242, 248, 252)'}}>
                                    <Grid style={{ display: 'flex', gap: '20px', flexDirection: 'column', padding: '17px' }}>
                                        <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '35px', }}>{item.attributes.company.data[0]?.attributes.company_name}</Typography>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', color: 'rgba(111, 111, 112, 1)', textAlign: "justify", maxWidth: "522px" }}>{item.attributes.company.data[0]?.attributes.about}</Typography>
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: '24px', height: '228px', padding: '17px' }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Minimum Investment</Typography>
                                                <Typography className={classes.textStyle} >${this.numberWithCommas(item.attributes.minimum_investment)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Allocation</Typography>
                                                <Typography className={classes.textStyle}>${this.numberWithCommas(item.attributes.allocation)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}  >Round</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.round}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Estimated Fees</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.estimated_fee}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '18px' }}>View Fund Info</Typography>
                                            <Grid style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '50%', padding: '6px', color: 'white' }} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${item.id}`}>
                                                <ArrowForwardIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>
                        <Button className={classes.investBtn} onClick={this.startupInvestmentDetails} data-test-id="investNowBtn">Invest Now</Button>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation}/>
            </Paper >
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(StartupInvestmentWeb);
// Customizable Area End
