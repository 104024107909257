import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imgShape } from "../../landingpage/src/assets";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { GroupArrowBig, imgBigOne, imgBigZero, imgBigTwo, imgBigThree } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImagesStyle: {
        flexDirection: 'column',
        minHeight: '871px',
        display: 'flex',
        backgroundRepeat: "round",
        alignItems: 'center',
        backgroundImage: `url(${imageHerosectionbackground})`,
        backgroundSize: "cover",
        gap: '123px', 
        '@media (max-width: 1280px)': {
            minHeight: '670px',
            gap: '70px'
        },
        '@media (max-width: 959px)': {
            minHeight: '570px',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            minHeight: '449px',
            gap: '26px'
        }
    },
    titleTextMainBoxStyle: {
        maxWidth: '1170px',
        position: 'relative',
        width: '100%',
        minHeight: '298px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '@media (max-width: 600px)': {
            minHeight: '250px',
        }
    },
    headerMainTextStyle: {
        fontSize: '56px',
        maxWidth: '940px',
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        fontWeight: 400,
        width: '100%',
        marginBottom: "29px",
        '@media (max-width: 1280px)': {
            fontSize: '52px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            marginBottom: "0px"
        }
    },
    futureTextStyle: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitiesBtnStyle: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        width: '100%',
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '319px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        height: '60px',
        background: 'rgba(255, 255, 255, 1)',
        marginTop: "64px",
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        },
        '@media (max-width: 600px)': {
            marginTop: "0px",
        }
    },
    startupStyle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        "& .startupBoxStyleBox": {
            display: 'flex',
            gap: '32px',
        },
        '@media (max-width: 959px)': {
            "& img": {
                display: "none"
            },
            "& .startupBoxStyleBox": {
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
        },
    },
    startupBoxStyle: {
        maxWidth: '370px',
        height: 'max-content',
        width: '100%',
        padding: '32px',
        gap: '24px',
        borderRadius: '32px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: 'rgba(229, 240, 248, 1)',
        "& .startupBoxText": {
            fontSize: '32px',
            fontFamily: 'Lato',
            fontWeight: 800, 
        },
        '@media (max-width: 959px)': {
            maxWidth:'none',
            top: '0 !important',
            "& .startupBoxText": {
                fontSize: '26px !important',
            }
        },
        '@media (max-width: 600px)': {
            "& .startupBoxText": {
                fontSize: '22px !important',
            }
        },
    },

    containerStyle: {
        display: "flex",
        justifyContent: "center",
        margin: '80px 0px',
        padding: '40px 0px',
        '@media (max-width: 959px)': {
            padding: "0px",
            margin: '70px 0px !important'
        },
        '@media (max-width: 600px)': {
            margin:'0'
        }
    },

    childContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        '@media(max-width:1200px)':{
            padding:'0 20px'
        },
        "& .directlyInvestText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600
        },
        "& .opportunityDataStyle": {
            display: 'flex',
            gap: '30px',
            flexWrap: 'nowrap'
        },
        '@media (max-width: 959px)': {
            "& .directlyInvestText": {
                fontSize: '32px',
            },
            "& .opportunityDataStyle": {
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
        },
        '@media (max-width: 600px)': {
            "& .directlyInvestText": {
                fontSize: '24px',
            }
        }
    },

    simplifiedBoxStyle: {
        width: '100%',
        maxHeight: '313px',
        marginTop: "53px",
        flexWrap: 'nowrap',
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            "& .subText": {
                textAlign: 'center',
                order: 1
            }
        },
        '@media(max-width:600px)':{
            maxHeight: 'none',
        }
    },

    spanStyle: {
        fontStyle: 'italic',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 800
    },

    investBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: '#ffffff',
        width: '100%',
        background: 'rgba(0, 105, 188, 1)',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '319px',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        }
    },
    Imgnumbers: {
        height: "154px",
        width: "75px"
    }
    ,
    FundBox: {
        fontSize: '32px',
        fontFamily: 'Lato',
        fontWeight: 600,
        position: 'relative',
        backgroundColor: '#F2F8FC',
        alignContent: "end",
        borderRadius: '16px',
        overflow: 'hidden',
        paddingTop:'82px',
        '@media (max-width: 959px)': {
            paddingTop:'35px',
        }
    }

    // Customizable Area End
};

export class FundInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { startupInvestData, opportunityData, feeStructureData } = this.state;

        return (
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImagesStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titleTextMainBoxStyle}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '272px',
                            top: '-15px',
                        }} />
                        <Typography className={classes.headerMainTextStyle}> Experience <br /><span className={classes.futureTextStyle}>Instant Diversification </span></Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontSize: '20px', color: '#ffffff', textAlign: 'center',padding:'0 20px' }}>Tap into the track record of top-tier managers with fund investing, maximising your potential for success.</Typography>
                        <Button className={classes.opportunitiesBtnStyle} onClick={this.showFundInvestmentDetails}>View Available Opportunities</Button>
                    </Box>
                </Box>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.childContainerStyle}>
                        <Typography className="directlyInvestText">
                            Why directly invest in <span className={classes.spanStyle} >Funds</span>?
                        </Typography>
                        <Grid item className={classes.startupStyle}>
                            <img src={GroupArrowBig} alt="" style={{ width: '100%', maxWidth: '800px' }} />
                            <Box className="startupBoxStyleBox">
                                <Box className={classes.startupBoxStyle} style={{ top: "10px" }}>
                                    <Typography className="startupBoxText" style={{ color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[0]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: 400, wordWrap: 'break-word' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[0]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBoxStyle} style={{ background: 'rgba(0, 105, 188, 1)', top: '-170px' }}>
                                    <Typography className="startupBoxText" style={{ color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[1]?.sub_title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Lato', fontWeight: 400, color: '#FFFFFF' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[1]?.content}
                                    </Typography>
                                </Box>
                                <Box className={classes.startupBoxStyle} style={{ top: '-330px' }}>
                                    <Typography className="startupBoxText" style={{ color: 'rgba(0, 105, 188, 1)' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[2]?.sub_title}
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Lato', }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[2]?.content}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {feeStructureData != undefined ?
                    <Grid container className={classes.containerStyle} style={{ marginBottom: '10px'}}>
                        <Grid container className={classes.childContainerStyle}>
                            <Typography className="directlyInvestText">
                                Simplified <span className={classes.spanStyle}>Fee Structure</span>
                            </Typography>
                            <Grid container className={classes.simplifiedBoxStyle}>
                                <Grid item md={6} xs={12} style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingRight: '15px' }}>
                                    <div style={{ position: 'absolute', top: "-77px", left: "50px", display: 'flex' }}>

                                        <img
                                            className={classes.Imgnumbers}
                                            src={imgBigZero}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigOne} className={classes.Imgnumbers} style={{ marginLeft: '-10px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox}>
                                        <Typography
                                            style={{ fontSize: "28px", fontWeight: 600, fontFamily: "Lato", padding: '32px' }}
                                        >
                                            <span className={classes.spanStyle}>Low minimum allocation amount</span> - Invest with flexibility and comfort
                                        </Typography>

                                    </Box>

                                </Grid>


                                <Grid item md={6} xs={12} className="subText" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: '18px', fontFamily: 'Lato' }}
                                        dangerouslySetInnerHTML={{ __html: feeStructureData[0]?.attributes?.content }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container className={classes.simplifiedBoxStyle}>
                                <Grid item md={6} xs={12} className="subText" style={{ display: 'flex', alignItems: 'center', paddingRight: '15px' }}>
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: '18px', fontFamily: 'Lato' }}
                                        dangerouslySetInnerHTML={{ __html: feeStructureData[1]?.attributes?.content }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} style={{ display: 'flex', position: 'relative', paddingLeft: '15px', flexDirection: 'column' }}>
                                    <div style={{ left: "50px", position: 'absolute', display: 'flex', top: "-77px" }}>
                                        <img
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                        />
                                        <img src={imgBigTwo} className={classes.Imgnumbers} style={{ marginLeft: '-10px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox} >
                                        <Typography
                                            style={{ fontWeight: 600, fontSize: "28px", fontFamily: "Lato", padding: '32px' }}
                                        >
                                            Invest without <span className={classes.spanStyle}>upfront fees</span> - pay only when you invest

                                        </Typography>

                                    </Box>

                                </Grid>


                            </Grid>

                            <Grid container className={classes.simplifiedBoxStyle}>
                                <Grid item md={6} xs={12} style={{ flexDirection: 'column', display: 'flex', paddingRight: '15px', position: 'relative', }}>
                                    <div style={{ top: "-77px", position: 'absolute', display: 'flex', left: "50px" }}>
                                        <img
                                            src={imgBigZero}
                                            className={classes.Imgnumbers}
                                            style={{ clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)', zIndex: 1 }}
                                        />
                                        <img src={imgBigThree} className={classes.Imgnumbers} style={{ marginLeft: '-10px', zIndex: 2 }} />
                                    </div>
                                    <Box className={classes.FundBox}>
                                        <Typography
                                            style={{ fontFamily: "Lato", fontSize: "28px", fontWeight: 600, padding: '32px' }}
                                        >
                                            <span className={classes.spanStyle}>Transparent fees</span> for informed investments - know the fees before you invest.
                                        </Typography>

                                    </Box>

                                </Grid>

                                <Grid item md={6} xs={12} className="subText" style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
                                    <Typography
                                        style={{ fontWeight: 400, fontSize: '18px', fontFamily: 'Lato' }}
                                        dangerouslySetInnerHTML={{ __html: feeStructureData[2]?.attributes?.content }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> : ''}

                <Grid container className={classes.containerStyle} style={{ marginTop: '10px'}} >
                    <Grid container className={classes.childContainerStyle}>
                        <Typography style={{ fontSize: '18px', fontFamily: 'Lato', color: 'rgba(0, 105, 188, 1)', fontWeight: 400, textAlign: 'center' }}>
                            From Our Past Successes to Your Future Opportunities
                        </Typography>
                        <Typography className="directlyInvestText">
                            Our Direct Fund Investments
                        </Typography>


                        <Grid className="opportunityDataStyle">
                            {opportunityData?.slice(0, 2).map((data: any) => (
                                <Grid style={{ display: 'flex', flexDirection: 'column', padding: '5px', border: '1px solid rgba(111, 111, 112, 1)', borderRadius: '12px', backgroundColor: 'rgb(242, 248, 252)' }}>
                                    <Grid style={{ display: 'flex', gap: '20px', flexDirection: 'column', padding: '17px' }}>
                                        <Typography style={{ fontFamily: 'Lato', fontWeight: 800, fontSize: '35px', }}>{data.attributes.company.data[0]?.attributes.company_name}</Typography>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', textAlign: "justify", maxWidth: "522px", color: 'rgba(111, 111, 112, 1)' }}>{data.attributes.company.data[0]?.attributes.about}</Typography>
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: '24px', height: '228px', padding: '17px' }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto' }}>Minimum Investment</Typography>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', }}>${this.numberWithCommas(data.attributes.minimum_investment)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto' }}>Allocation</Typography>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', }}>${this.numberWithCommas(data.attributes.allocation)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto' }}>Round</Typography>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', }}>{data.attributes.round}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto' }}>Estimated Fees</Typography>
                                                <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', }}>{data.attributes.estimated_fee}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                                            <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '18px' }}>View Fund Info</Typography>
                                            <Grid style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '50%', padding: '6px', color: 'white' }} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${data.id}`}>
                                                <ArrowForwardIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>
                        <Button className={classes.investBtnStyle} onClick={this.showFundInvestmentDetails} data-test-id="fundInvestBtn">Invest Now</Button>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation} />
            </Paper>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(FundInvestmentWeb);
// Customizable Area End
