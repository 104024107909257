import React from "react";

// Customizable Area Start
import { Box, Typography, Popover, List, ListItem, ListItemText, ListSubheader, Badge, Button, OutlinedInput,Container } from "@material-ui/core";
import { imgBanner, imgShape } from "./assets";
import { styled } from "@material-ui/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formatDate = (timestamp: string) => {
    const dateObject = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

    return dateObject.toLocaleDateString('en-US', options);
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <IconButton aria-label="notification" onClick={this.openDialog} style={{ height: '35px' }}>
          <StyledBadge color="secondary" variant="dot" invisible={this.makeDotInvisible()}>
            <NotificationsIcon style={{ color: this.props.color }} />
          </StyledBadge>
        </IconButton>
        <StyledPopover
          id={Boolean(this.state.anchorEl) ? 'simple-popover' : undefined}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.closeDialog}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {this.state.loading &&
            <Container style={{ height: '60vh'}}>
              <Loader loading={this.state.loading} />
            </Container>
          }
          {!this.state.loading && this.state.notificationRecords?.length > 0 &&
          <>
            <Typography style={{
              fontSize: '20px',
              fontWeight: 800,
              fontFamily: 'Lato',
              color: 'rgba(34, 34, 34, 1)',
              padding: '8px 0px',
            }}>
              All Notifications
            </Typography>
            <List >
              <ListItem
                alignItems="flex-start"
                style={{
                  width: '100%',
                  padding: '8px 0px',
                }}
              >
                <StyledOutline
                  value={this.state.searchValue}
                  onChange={this.handleSearch}
                  placeholder="Search"
                  startAdornment={<SearchIcon style={{ marginRight: '10px' }} />}
                />
              </ListItem>
              <Loader loading={this.state.loading} />
              {this.state.notificationRecords.filter((item: any) =>
                item.attributes.contents.toString().toLowerCase().includes(this.state.searchValue.toString().toLowerCase())
              ).map((item: any) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    style={{
                      width: '100%',
                      padding: '8px 0px',
                      borderWidth: '1px 0px',
                      borderStyle: 'solid',
                      borderColor: 'rgba(189, 189, 189, 1)'
                    }}
                  >
                    <ListItemText
                      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                      primary={
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: 'Roboto'
                          }}>
                          {item.attributes.contents}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                            color: 'rgba(130, 130, 130, 1)'
                          }}
                        >
                          {this.formatDate(item.attributes.created_at)}
                        </Typography>
                      }
                    />
                  </ListItem>
                )
              })}
            </List> 
            </> 
          }
          {!this.state.loading && this.state.notificationRecords?.length == 0 &&
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <Typography style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Lato' }}>Hi User,</Typography>
              <Box style={{ display: 'flex', gap: '8px', padding: '0px 12px' }}>
                <ErrorOutlineIcon style={{ color: 'rgba(130, 130, 130, 1)', width: '14px' }} />
                <Typography style={{ fontSize: '16px', color: 'rgba(130, 130, 130, 1)', fontFamily: 'Roboto' }}>This is start of your notification history</Typography>
              </Box>
              <Box style={webStyle.noDataBox}>
                <Box style={webStyle.noDataTxt} >
                  No notifications yet
                </Box>
              </Box>
              <Typography style={{ fontSize: '16px', fontFamily: 'Roboto' }}>You’ll receive notifications related to</Typography>
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Box style={webStyle.topicBox}>
                  <Typography style={webStyle.topics}>New Investment Opportunity</Typography>
                  <img src={imgShape} style={webStyle.topicImg} />
                </Box>
                <Box style={webStyle.topicBox} sx={{ marginLeft: '40px' }}>
                  <Typography style={webStyle.topics}>Investment Status</Typography>
                  <img src={imgShape} style={webStyle.topicImg} />
                </Box>
                <Box style={webStyle.topicBox}>
                  <Typography style={webStyle.topics}>Investment Documentation</Typography>
                  <img src={imgShape} style={webStyle.topicImg} />
                </Box>
                <Box style={webStyle.topicBox} sx={{ marginLeft: '40px' }}>
                  <Typography style={webStyle.topics}>Wire Transfer</Typography>
                  <img src={imgShape} style={webStyle.topicImg} />
                </Box>
                <Box style={webStyle.topicBox}>
                  <Typography style={webStyle.topics}>Investment Queries</Typography>
                  <img src={imgShape} style={webStyle.topicImg} />
                </Box>
              </Box>
              <BrowseButton>
                Browse Opportunities
              </BrowseButton>
            </Box>
          }
        </StyledPopover>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledBadge = styled(Badge)({
  "& .MuiBadge-colorSecondary": {
    backgroundColor: 'rgba(0, 105, 188, 1)'
  }
});

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper": {
    borderRadius: '16px',
    maxWidth: '391px',
    height: '672px',
    padding: '24px 24px 0px 24px',
    '@media (max-width: 450px)': {
      padding: '12px 12px 0px 12px',
      maxWidth: '325px'
    }
  }
});

const StyledOutline = styled(OutlinedInput)({
  width: '100%',
  borderRadius: '8px',
  height: '48px',
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(0, 105, 188)',
    },
  },
});

const BrowseButton = styled(Button)({
  background: 'rgba(0, 105, 188, 1)',
  borderRadius: '12px',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: '20px',
  fontWeight: 700,
  width: '100%',
  height: '60px',
  padding: '10px 12px',
  fontFamily: 'Roboto',
  textTransform: 'none',
  "&:hover": {
    backgroundColor: "rgba(0, 105, 188, 1)",
  },
});

const webStyle = {
  noDataBox: {
    backgroundImage: `url(${imgBanner})`,
    height: '200px',
    width: '100%',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: '12px',
  },
  noDataTxt: {
    background: 'rgba(0, 105, 188, 1)',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    height: '26px',
    width: '136px',
    borderRadius: '0px 4px 0px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px'
  },
  topicBox: {
    background: 'rgba(229, 240, 248, 1)',
    height: '36px',
    width: '260px',
    padding: '0px 16px',
    borderRadius: '8px',
    position: 'relative' as const,
    display: 'flex',
    alignItems: 'center'
  },
  topics: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  topicImg: {
    position: 'absolute' as const,
    right: '-12px',
    top: '2px'
  }
};
// Customizable Area End
